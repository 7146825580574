import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextGroup from "../components/TextGroup"
import PeopleList from "../components/PeopleList"
import TeamList from "../components/TeamList"
import {PanelGreen} from "../components/ContentPanel"
import Cta from "../components/Cta"
import styled from "styled-components"
import ShapeTriangle from "../components/shapes/Triangle"
import ShapeCrescent from "../components/shapes/Crescent"

const AboutShape = styled.div`
  position: absolute;
  right: -150px;
  bottom: 72%;
  transform: rotate(-45deg);
  display: none;

  @media (min-width: 650px) {
    display: block;
  }    
`

const AdvisoryShape = styled.div`
  position: absolute;
  left: -50px;
  bottom: 50%;
  transform: rotate(10deg);
  display: none;

  @media (min-width: 1220px) {
    display: block;
  }  
`

const IndexPage = () => (

  <Layout>

    <SEO title="Imagine the world to come" description="Reset is a chance for your voice to be heard, so that you can help shape a fairer, greener, future for the UK" />

    <PanelGreen>
      <TextGroup
        title="About Reset"
        copy="<p><b>Reset is an initiative of the cross party All Party Parliamentary Group on the Green New Deal, bringing people and politicians together to shape the future.</b></p><p>Through surveys, workshops, discussion groups, special parliamentary evidence sessions and other online events, we’re hoping to hear as many different voices as possible. And then we want to put those voices to work in the pursuit of a better way.</p>"
      />

      <AboutShape>
        <ShapeCrescent />
      </AboutShape>

    </PanelGreen>

    <PeopleList />

    <PanelGreen>
      <TextGroup
        title="The Advisory Group for the All Party Parliamentary Group on the Green New Deal"
        copy="<p>Professor Kevin Anderson, Tyndall Centre; Fernanda Balata, New Economics Foundation; Adrienne Buller, Common Wealth; Mel Evans, Senior Climate Campaigner, Greenpeace UK; Tony Greenham, South West Mutual; Professor Andy Gouldson, University of Leeds, Place Based Climate Action Network; Colin Hines, Convenor, Green New Deal Group; Fatima Ibrahim, Green New Deal UK; Professor Richard Murphy, Corporate Accountability Network; Ann Pettifor, Prime Economics; Asad Rehman, War on Want; Professor Nick Robins, LSE/Grantham Institute; Andrew Simms, Rapid Transition Alliance; Professor Lorraine Whitmarsh, Director of the Centre for Climate Change and Social Transformations; and, Professor Rebecca Willis, Lancaster University.</p>"
      />

      <AdvisoryShape>
        <ShapeTriangle />
      </AdvisoryShape>

    </PanelGreen>

    <TeamList />

    <Cta />

  </Layout>

)

export default IndexPage
