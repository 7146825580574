import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import shapeWhite from "../images/background_shape_white.png"
import Person from "./Person"
import ShapeCrescent from "./shapes/Crescent"
import ShapeRectangle from "./shapes/Rectangle"
import ShapeRoundall from "./shapes/Roundall"

const Wrap = styled.div `
  background-color: ${({ theme }) => theme.colorResetYellow};
  background-image: url(${shapeWhite});
  background-size: contain;
  padding: ${({ theme }) => theme.PadPanel};
  position: relative;
`

const Title = styled.h2 `
  color: ${({ theme }) => theme.colorResetBlue};
  font-size: ${({ theme }) => theme.FontXlarge};
  text-align: center;
`

const People = styled.div `
  max-width: 1300px;
  margin: 140px auto 6rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) ) ;
  grid-column-gap: 20px;  
  grid-row-gap: 140px;
`

const SignOff = styled.p `
  color: ${({ theme }) => theme.colorResetBlue};
  font-weight: 900;
  max-width: 900px;
  margin: auto;
  text-align: center;
`

const Shape1 = styled.div `
  position: absolute;
  left: -20px;
  bottom: 91%;
  display: none;

  @media (min-width: 650px) {
    display: block;
  }    
`

const Shape2 = styled.div `
  position: absolute;
  right: -80px;
  bottom: 70%;
  transform: rotate(-70deg);
  display: none;

  @media (min-width: 650px) {
    display: block;
  }    
`

const Shape3 = styled.div `
  position: absolute;
  left: -80px;
  bottom: 50%;
  transform: rotate(90deg);
  display: none;

  @media (min-width: 650px) {
    display: block;
  }    
`

const Shape4 = styled.div `
  position: absolute;
  right: -70px;
  bottom: 10%;
  transform: rotate(-230deg);
  display: none;

  @media (min-width: 650px) {
    display: block;
  }    
`

const PeopleList = ( { data } ) => (

  <StaticQuery
    query={graphql`
      query PeopleList {
        allDatoCmsPerson(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              name
              bio
              headShot {
                fixed(width: 200, imgixParams: {fm: "png", auto: "compress"}) {
                  ...GatsbyDatoCmsFixed
                }
              }
            }
          }
        }
      }
    `}
    
    render={data => (

      <Wrap>

        <Title>The All Party Parliamentary Group on the Green New Deal</Title>

        <People>

          <Shape1>
            <ShapeCrescent />
          </Shape1>

          <Shape2>
            <ShapeRectangle />
          </Shape2>   

          <Shape3>
            <ShapeRectangle />
          </Shape3>     

          <Shape4>
            <ShapeRoundall />
          </Shape4>               
      
          {data.allDatoCmsPerson.edges.map(({ node: person }) => (

            <Person
              headshot={person.headShot.fixed}
              name={person.name}
              bio={person.bio}
            />

          ))}

        </People>

        <SignOff>The Secretariat for the All-Party Parliamentary Group is provided by the Green New Deal Group of economists, politicians, environmentalists and energy experts who employ a single staff member, Ruth Potts.</SignOff>

      </Wrap>

    ) 
  }

  ></StaticQuery>

)

export default PeopleList