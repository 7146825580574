import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Person from "./Person"
import ShapeRectangle from "../components/shapes/Rectangle"
import ShapeCrescent from "./shapes/Crescent"

const Wrap = styled.div `
  background-color: ${({ theme }) => theme.colorResetWhite};
  padding: 6.25rem 0 9.375rem 2rem;
  position: relative;

  @media (min-width: 1500px) {
    padding: ${({ theme }) => theme.PadPanel};
  }      
`

const Title = styled.h2 `
  color: ${({ theme }) => theme.colorResetBlue};
  font-size: ${({ theme }) => theme.FontXlarge};
  text-align: center;
`

const Description = styled.p `
  font-size: ${({ theme }) => theme.FontMedium};
  line-height: 1.3;
  max-width: 1100px;
  margin: 1rem auto 4rem;
  text-align: center;
`

const Team = styled.div `
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 60px;
  height: 500px;
  overflow-x: auto;
  display: flex;

  ::-webkit-scrollbar {
    width: 12px;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2); 
    border-radius: 0;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: ${({ theme }) => theme.colorResetGreen};
    -webkit-box-shadow: none; 
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255,0,0,0.4); 
  }
`

const TeamMember = styled(Person) `
  width: 300px;
  flex-shrink: 0;
  margin: 0 0.5rem 2rem;
  padding-bottom: 3rem;
  
  &:after {
    background-color: #fff; 
  }
`

const Shape1 = styled.div `
  position: absolute;
  right: -60px;
  bottom: 92%;
  transform: rotate(-13deg);  
  display: none;

  @media (min-width: 650px) {
    display: block;
  }      
`

const Shape2 = styled.div `
  position: absolute;
  left: -40px;
  bottom: 10%;
  transform: rotate(-35deg);  
  display: none;

  @media (min-width: 650px) {
    display: block;
  }      
`

const TeamList = ( { data } ) => (

  <StaticQuery
    query={graphql`
      query TeamList {
        allDatoCmsTeamMember(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              name
              jobTitle
              link
              linkTitle
              headShot {
                fixed(width: 200, imgixParams: {fm: "png", auto: "compress"}) {
                  ...GatsbyDatoCmsFixed_noBase64
                }
              }              
            }
          }
        }
      }
    `}
    
    render={data => (

      <Wrap>

        <Title>The Reset team</Title>

        <Description>Reset has been made possible by a group of people and organisations, many of whom have given their time for free:</Description>

        <Shape1>
          <ShapeRectangle />
        </Shape1>

        <Shape2>
          <ShapeCrescent />
        </Shape2>

        <Team>

          {data.allDatoCmsTeamMember.edges.map(({ node: teamMember }) => (

            <TeamMember 
              headshot={teamMember.headShot.fixed}
              name={teamMember.name}
              jobTitle={teamMember.jobTitle}
              link={teamMember.link}
              linktext={teamMember.linkTitle}
            />

          ))}

        </Team>

      </Wrap>

    ) 
  }

  ></StaticQuery>

)

export default TeamList