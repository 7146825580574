import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'

const PersonWrap = styled.div `
  width: 100%;
  height: auto;
  position: relative;
  /* filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.2)); */

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colorResetWhite}; 
    clip-path: polygon(4% 5%, 97% 1%, 100% 100%, 0% 100%);
  }
`

const PersonCard = styled.div `
  top: -50px;
  position: relative;
  z-index: 1;
  padding: 0 1rem;
  text-align: center;
`

const Bio = styled.p `
  font-size: ${({ theme }) => theme.FontSmall};
  margin-bottom: 3rem;
`
const LinkWrap = styled.div `
  margin-top: 0.2rem;

  a:link, a:visited {
    color: ${({ theme }) => theme.colorResetBlack};
    text-decoration: none;
  }

  a:hover {
    color: ${({ theme }) => theme.colorResetBlue};
  }  
`

const JobTitle = styled.p `
  font-size: ${({ theme }) => theme.FontSmall};
  margin-bottom: 0;
`

const Name = styled.h3 `
  color: ${({ theme }) => theme.colorResetBlue};
  margin: 1rem auto;
`

const Person = ({ className, name, bio, headshot, jobTitle, link, linktext }) => (

  <PersonWrap className={className}>
    <PersonCard>
      {headshot && <Img fixed={headshot} />}
      {name && <Name>{name}</Name>}
      {bio && <Bio>{bio}</Bio>}
      {jobTitle && <JobTitle>{jobTitle}</JobTitle>}
      {linktext && link && <LinkWrap><a href={link}>{linktext}</a></LinkWrap>}
    </PersonCard>
  </PersonWrap>

)

export default Person
